//#region custom fonts
/* @font-face {
    font-family: "My-Font";
    src: url("./../fonts/My-Font.woff") format("woff"),
      url("./../fonts/My-Font.eot"),
      url("./../fonts/My-Font.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
} */
//#endregion
//#region limits (max-width)
$i: 0;
@while $i <= 2000 {
  $i: $i + 20;
  .is-limited-#{$i} {
    max-width: #{$i}px;
  }
}
//#endregion

//#region bootstrap variables
$container-max-widths: (
  xl: 1400px,
);

$enable-transitions: false;
$enable-shadows: false;
$enable-rounded: false;
$enable-caret: false;
$breadcrumb-divider: quote(">");

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2.5,
  6: $spacer * 3,
  7: $spacer * 3.5,
  8: $spacer * 4,
  9: $spacer * 4.5,
  10: $spacer * 5,
  11: $spacer * 5.5,
  12: $spacer * 6,
  13: $spacer * 6.5,
  14: $spacer * 7,
  15: $spacer * 7.5,
  16: $spacer * 8,
  17: $spacer * 8.5,
  18: $spacer * 9,
  19: $spacer * 9.5,
  20: $spacer * 10,
);
$grid-gutter-width: 1.5rem;
$gutters: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2.5,
  6: $spacer * 3,
  7: $spacer * 3.5,
  8: $spacer * 4,
  9: $spacer * 4.5,
  10: $spacer * 5,
  11: $spacer * 5.5,
  12: $spacer * 6,
  13: $spacer * 6.5,
  14: $spacer * 7,
  15: $spacer * 7.5,
  16: $spacer * 8,
  17: $spacer * 8.5,
  18: $spacer * 9,
  19: $spacer * 9.5,
  20: $spacer * 10,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);


$font-family-base: Arial, Helvetica, sans-serif;
$font-family-serif: "Montserrat", sans-serif;
$text-color: #1A1A1A;
$body-bg: #ffffff;
$body-color: $text-color;
$link-color: $text-color;
$border-color: #eeeeee;

$primary: #CB0001;
$secondary: #00b772;
$success: #00b772;
$info: #09123E;
$warning: #e56700;
$danger: #e50019;
$light: #f5f5f5;
$dark: #666666;
//#endregion

//#region slick variables
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
//#endregions

//#region hamburgers variables
$hamburger-padding-x: 0px;
$hamburger-padding-y: 15px;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: $primary;
$hamburger-layer-border-radius: 0px;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
//#endregion
$accordion-icon-color: $primary;
$accordion-icon-active-color: $primary;