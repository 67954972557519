// Importazione del file parziale _variables.scss
@import "variables";

// Importazione dei file SCSS di Bootstrap con utilizzo delle sue Utilities API
@import "~bootstrap/scss/vendor/rfs";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "bs-colors";
@import "~bootstrap/scss/utilities";
@import "bs-utilities";
@import "~bootstrap/scss/bootstrap";

// Importazione del file per i carousel Slick
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

// Importazione del file per animazione menu hambuger
@import "~hamburgers/_sass/hamburgers/hamburgers";

//#region misc
* {
  outline: none !important;
  -webkit-font-smoothing: antialiased;

  &:focus {
    box-shadow: none !important;
  }
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: $secondary !important;
}

::selection {
  background: $secondary;
  color: $white;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: $light;
  border: $white;
}

*::-webkit-scrollbar-thumb {
  background: $text-color;
  border-radius: 7px;
}

*::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

//#endregion

//#region utilities
a {
  text-decoration: none;
  transition: all .3s ease-in-out;

  &:hover {
    color: $primary;
  }
}

//#endregion

//#region stickybar
.stickybar {
  background-color: $white;
  z-index: 9900;
  transition: all 300ms linear;

  .navbar-lang {
    &__item {
      @extend .text-uppercase, .fw-light, .fs-default-small, .mx-2, .font-serif;

      &:first-child {
        @extend .ms-0;
      }

      &:last-child {
        @extend .me-0;
      }
    }
  }

  &.stickybar--sticked {
    box-shadow: 0px 6px 15px -4px rgba($color: $dark, $alpha: .5);

    @include media-breakpoint-down(md) {
      .navbar-brand {
        img {
          height: 40px;
          width: auto;
        }
      }
    }
  }
}

//#endregion
//#region content

.video-background {
  position: relative;
  height: 100%;

  &__container {
    width: 100%;
    height: 750px;
    object-fit: cover;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 4;
    width: 90%;
    display: flex;
    flex-direction: column;
    height: 85%;
    padding-top: 90px;

    &__title {
      @extend .text-uppercase, .font-serif, .fs-extra, .bg-white, .mx-auto, .fw-semibold;
      color: $info;
      padding: 15px 20px;
      max-width: 70%;

    }

    &__countdown {
      @extend .m-auto;

      .details__countdown {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: right;

        >div {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          padding: 0 50px;
          border-right: 4px solid $white;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
            border-right: 0;
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            >span {
              @extend .fw-light, .font-serif, .text-uppercase, .fs-title;
              color: $white;

              &:first-child {
                font-size: 150px !important;
                line-height: 140px !important;
                @extend .fw-semibold;
              }
            }
          }
        }
      }
    }

    &__bottom {
      margin-top: auto;
      ;

      &__text {
        @extend .text-white, .fs-slogan, .text-uppercase, .font-serif;
        flex-direction: column;
        display: flex;
        margin-top: auto;

        &__bounce {
          width: 32px;
          height: auto;
          margin: 20px auto 0;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .video-background {
    &__content {
      padding-top: 50px;

      .details__countdown {
        >div {
          padding: 0 30px;
          border-right: 2px solid $white;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
            border-right: 0;
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            >span {
              font-size: 25px !important;

              &:first-child {
                font-size: 80px !important;
                line-height: 70px !important;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .video-background {
    &__content {
      &__title {
        width: 100%;
        max-width: 100%;
        line-height: 1.4rem;
        font-size: 25px !important;
      }

      .details__countdown {
        flex-direction: column;

        >div {
          padding: 15px 0 !important;
          border-bottom: 1px solid $white;
          border-right: 0;
          text-align: center;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            >span {
              font-size: 25px !important;

              &:first-child {
                font-size: 50px !important;
                line-height: 30px !important;
              }
            }
          }

          &:last-child {
            border-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.bounce {
  animation: bounce 2s ease-in-out infinite;
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }

  80% {
    transform: translateY(-40%);
  }

  90% {
    transform: translateY(0%);
  }

  95% {
    transform: translateY(-20%);
  }

  97% {
    transform: translateY(0%);
  }

  99% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(0);
  }
}

.accordion {
  .accordion-item {
    border: 0;
    margin-bottom: 10px;

    .accordion-header {
      .accordion-button {
        padding: 0;

        &:not(.collapsed) {
          background-color: $white;
          box-shadow: none;
          color: $text-color;

        }

        .ico-accordion {
          margin-right: 15px;
        }

        span {
          @extend .text-uppercase, .font-serif, .fs-subtitle, .fw-semibold;
          padding-bottom: 10px;
          border-bottom: 1px solid $dark;
          width: 100%;
        }
      }
    }

    .accordion-collapse {
      .accordion-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 30px;

        .accordion-first-column {
          @extend .fst-italic;

          &__meta {
            @extend .text-dark, .fs-note, .mb-4;
          }

          &__title {
            @extend .fw-bold, .ls-tight, .fs-slogan, .mb-4, .lh-tight;
          }

          &__text {
            @extend .fs-default, .lh-tight;
          }
        }
      }

      .accordion-second-column {
        @extend .fst-italic;

        &__text {
          @extend .fs-default;
        }
      }
    }
  }
}

.modal {
  z-index: 9999;

  .modal-content {
    border: 0;
    background-color: transparent;
  }
}

.modal-backdrop {
  z-index: 9998;
}

.accordion-cta {
  padding: 40px 0;
  background-image: url(/images/missile.png);
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: left bottom;


}

.btn-oparts {
  background-color: $primary;
  color: $white;
  font-size: 22px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;

  &:hover {
    color: $white;
    opacity: .7;

  }
}

.bg-last {
  background-image: url(/images/bg-last.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
  min-height: 230px;
  @extend .font-serif, .lh-tight;

  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    max-width: 550px;
    text-transform: uppercase;
    font-size: 36px;
    text-align: center;
  }
}

//#endregion